
import { Component, Vue } from 'vue-property-decorator'
import { Page, Table } from '../../types/common'
import { PageType } from '../../types/mechanics'

@Component({
  name: 'mechanicsList'
})
export default class extends Vue {
  private searchData = {
    machineName: ''
  }

  private total = 0
  private page = 1
  private size = 10

  private tableData: Table = {
    loading: false,
    tr: [],
    data: []
  }

  created () {
    this.getTableList()
  }

  getTableList () {
    this.tableData.loading = true
    this.$axios
      .get<Page<PageType>>(this.$apis.mechanics.selectMachineByPage, {
        ...this.searchData,
        page: this.page,
        size: this.size
      })
      .then((res) => {
        this.tableData.data = res.list || []
        this.total = res.total || 0
        this.tableData.loading = false
      })
      .catch((err) => {
        this.tableData.loading = false
        console.log(err)
      })
  }

  // 查询
  onSearch () {
    console.log(this.searchData)
    this.page = 1
    this.getTableList()
  }

  // 新增
  onAdd () {
    this.$router.push({ name: 'mechanicsAdd' })
  }

  // 详情
  onDetail (id: string) {
    this.$router.push({ name: 'mechanicsDetail', params: { id } })
  }

  // 编辑
  onEdit (id: string) {
    this.$router.push({ name: 'mechanicsUpdate', params: { id } })
  }

  // 删除
  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios.post(this.$apis.mechanics.deleteMachine, {
          manageId: id
        }).then(() => {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.page = 1
          this.getTableList()
        })
          .catch((err) => {
            this.$message.error(err)
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
